import { USER_ROLES } from '@commons/data/constants';
import store from '@commons/store';

export const UI_ABILITIES = {
  [USER_ROLES.ADMIN]: [
    'sidebar.subscriptions',
    'sidebar.customers',
    'sidebar.dashboard',
    'sidebar.accounts',
    'sidebar.users',
    'sidebar.usersManage',
    'sidebar.usersActivities',
    'sidebar.products',
    'sidebar.disputes',
    'sidebar.risks',
    'sidebar.settlements',
    'sidebar.declined',
    'sidebar.fees',
    'sidebar.payouts',
    'sidebar.transactions',
    'sidebar.manualPayouts',
    'sidebar.system',
    'sidebar.ledger',
    'sidebar.hostedSessions',
    'payouts.ack',
    'payouts.hold',
    'fees.view',
    'payouts.beneficiaryFilter',
    'payouts.forReview',
    'fees.appliedFees',
    'fees.forReview',
    'tx.forReview',
    'tx.processing',
    'widgets.statsCards',
    'dashboard.selectDate',
    'charts.transactions',
    'widgets.recentTransactions',
    'widgets.recentBalance',
    'widgets.recentDispute',
    'disputes.disputeDetail',
    'disputes.notes',
    'tx.refund',
    'tx.void',
    'tx.customer',
    'widgets.ledgerAcc',
    'widgets.comments',
    'widgets.batchActions',
    'widgets.subMerchantId',
    'widgets.balanceDetailJournal',
    'table.processors',
    'tx.dbJSON',
    'users.rolesFilter',
    'dashboard.balance',
    'dashboard.balance-detail',
    'dashboard.product-filter',
    // 'sidebar.developer',
    'dashboard.processors-filter',
    'sidebar.invoices',
    'applicant.tags',
    'submerchant.tags',
    'tx.reconciliation',
    'tx.risk',
    'accounts.tokenization.rateLimit'
  ],
  [USER_ROLES.UNDERWRITER]: [
    'sidebar.dashboard',
    'sidebar.accounts',
    'widgets.comments',
    'widgets.subMerchantId',
    'dashboard.selectDate',
    'table.processors',
    'charts.transactions',
    'widgets.statsCards',
    'widgets.recentTransactions',
    'widgets.recentDispute',
    'users.rolesFilter',
    'dashboard.balance',
    'dashboard.balance-detail',
    'applicant.tags',
    'submerchant.tags',
    'tx.reconciliation',
    'tx.risk'
  ],
  [USER_ROLES.MANAGER]: [
    'sidebar.dashboard',
    'sidebar.settlements',
    'sidebar.declined',
    'sidebar.fees',
    'sidebar.payouts',
    'sidebar.transactions',
    'sidebar.disputes',
    'sidebar.virtualTerminal',
    'sidebar.applePay',
    'sidebar.customers',
    'sidebar.hostedSessions',
    // 'sidebar.subscriptions',
    'dashboard.selectDate',
    'charts.transactions',
    'widgets.recentSettlements',
    'widgets.recentTransactions',
    'widgets.recentBalance',
    'widgets.recentDispute',
    'widgets.statsCards',
    'hideAuthTxs',
    'disputes.disputeDetail',
    'tx.refund',
    'tx.void',
    'tx.customer',
    'hosted.paylink',
    // 'hosted.invoice',
    'sidebar.invoices',
    'sidebar.merchant.accounts',
    'merchant.account.overview',
    // 'sidebar.pci-compliance'
    'dashboard.balance'
  ],
  [USER_ROLES.APPLICANT]: [
    'accounts.new',
    'dashboard.loanNotification',
    'sidebar.merchant.accounts',
    'merchant.account.overview',
    'dashboard.balance'
    // 'sidebar.pci-compliance'
  ],
  [USER_ROLES.MERCHANT_ADMIN]: [
    'sidebar.users',
    'sidebar.usersManage',
    'sidebar.merchant.accounts',
    'merchant.account.overview',
    'dashboard.balance'
    // 'sidebar.pci-compliance'
  ],
  [USER_ROLES.EMPLOYEE]: [
    'sidebar.dashboard',
    'sidebar.settlements',
    'sidebar.declined',
    'sidebar.fees',
    'sidebar.payouts',
    'sidebar.transactions',
    'sidebar.virtualTerminal',
    'sidebar.applePay',
    'sidebar.customers',
    'sidebar.hostedSessions',
    'dashboard.selectDate',
    'charts.transactions',
    'widgets.recentSettlements',
    'widgets.recentTransactions',
    'widgets.recentBalance',
    'widgets.statsCards',
    'hideAuthTxs',
    'tx.refund',
    'tx.void',
    'tx.customer',
    'hosted.paylink',
    // 'hosted.invoice',
    'sidebar.invoices',
    'dashboard.balance'
    // 'sidebar.pci-compliance'
  ],
  [USER_ROLES.ACCOUNTING]: [
    'sidebar.dashboard',
    'sidebar.disputes',
    'sidebar.settlements',
    'sidebar.declined',
    'sidebar.fees',
    'sidebar.payouts',
    'sidebar.transactions',
    'dashboard.selectDate',
    'disputes.disputeDetail',
    'charts.transactions',
    'widgets.recentSettlements',
    'widgets.recentTransactions',
    'widgets.recentBalance',
    'widgets.statsCards',
    'widgets.recentDispute',
    'hideAuthTxs',
    'dashboard.balance'
    // 'sidebar.pci-compliance'
  ],
  [USER_ROLES.PROVIDER]: [
    'payouts.beneficiaryFilter',
    'payouts.forReview',
    'fees.forReview',
    'tx.forReview',
    'tx.processing',
    'sidebar.service-provider.accounts',
    'sidebar.service-provider.transactions',
    'service-provider.account.overview',
    'sidebar.dashboard',
    'sidebar.disputes',
    'sidebar.settlements',
    'sidebar.declined',
    'sidebar.fees',
    'sidebar.payouts',
    'sidebar.transactions',
    'sidebar.hostedSessions',
    'charts.transactions',
    'widgets.statsCards',
    'widgets.recentTransactions',
    'widgets.recentDispute',
    'dashboard.selectDate',
    'dashboard.balance',
    'dashboard.balance-detail',
    'sidebar.invites',
    'disputes.disputeDetail',
    'dashboard.product-filter',
    'sidebar.developer',
    'sidebar.service-provider.products'
  ],
  [USER_ROLES.BANK]: [
    'dashboard.selectDate',
    'sidebar.dashboard',
    'sidebar.accounts',
    'sidebar.products',
    'sidebar.transactions',
    'tx.processing',
    'tx.forReview',
    'applicant.tags',
    'submerchant.tags',
    'widgets.comments',
    'widgets.recentTransactions',
    'widgets.recentDispute',
    'charts.transactions',
    'widgets.statsCards',
    'sidebar.usersActivities',
    'sidebar.hostedSessions',
    'sidebar.declined',
    'sidebar.fees',
    'sidebar.payouts',
    'sidebar.settlements',
    'tx.reconciliation'
  ],
  hasFeatureEnabled(wantedFeature = '') {
    return store.getters.accountNames
      .map(account => account.features)
      .some(accountFeatures =>
        Object.entries(accountFeatures || {}).some(
          ([featureName, featureValue]) =>
            featureName === wantedFeature && featureValue?.enabled === true
        )
      );
  },
  check(acl, ability, feature = '') {
    try {
      for (const a of acl) {
        if (UI_ABILITIES[a.role] && UI_ABILITIES[a.role].includes(ability)) {
          if (feature && !this.hasFeatureEnabled(feature)) {
            return false;
          }
          return true;
        }
      }
      return false;
    } catch (error) {
      return false;
    }
  }
};
